import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import Layout from '../components/Layout';
import Gallery from '../components/Gallery';

import iconArrowGold from '../img/iconarrow-thin.svg';
import facebook from '../img/social/facebook.svg';
import instagram from '../img/social/instagram.svg';
import be from '../img/social/be.svg';

// eslint-disable-next-line
export const ContactPageTemplate = ({ data }) => {
  const imagesArray = data.header.gallery;
  // const imagesArray = data.header.gallery.reduce((prev, curr) => {
  //   return [...prev, { image: getImage(curr.image), alt: curr.alt }];
  // }, []);
  return (
    <main className="contact-page">
      <div className="topfade"></div>
      <div className="grain"></div>
      <div className="page-indicator">HERE</div>
      <section className="page-section contact-section">
        <div className="heading-container heading-container-first">
          <img
            className="iam-link-arrow"
            src={iconArrowGold}
            alt="Title Arrow - Decoration"
            // style={{ width: '60px' }}
          />
          <h2 className="heading-title">
            I am <span className="heading-title-alt">Studio</span>
          </h2>
        </div>
        <p className="section-description">{data.header.description}</p>
      </section>
      <Gallery imagesArray={imagesArray} />
      <section className="page-section contact-section">
        <div className="heading-container">
          <img
            className="iam-link-arrow"
            src={iconArrowGold}
            alt="Title Arrow - Decoration"
            // style={{ width: '60px' }}
          />
          <h2 className="heading-title">
            I am <span className="heading-title-alt">Contact</span>
          </h2>
        </div>
        <p className="section-description">{data.contact.description}</p>
        <div className="contact-details">
          <div className="contact-detail contact-detail-email">
            <p className="contact-detail-label">E-Mail</p>
            <a
              className="contact-detail-value"
              href={`mailto:${data.contact.email}`}
            >
              {data.contact.email}
            </a>
          </div>
          <div className="contact-detail contact-detail-address">
            <p className="contact-detail-label">Address</p>

            <a
              href="https://goo.gl/maps/MhdBwTGbyRopdsGH7"
              target="_blank"
              rel="noopener noreferrer"
              className="contact-detail-value"
            >
              {data.contact.address}
            </a>
          </div>
          <div className="contact-detail contact-detail-phone">
            <p className="contact-detail-label">Phone</p>
            <a
              className="contact-detail-value"
              href={`tel:${data.contact.phone}`}
            >
              {data.contact.phone}
            </a>
          </div>
        </div>
        <div className="contact-detail contact-detail-social-container ">
          <a
            href={data.contact.facebook}
            target="_blank"
            rel="noopener noreferrer"
            className="contact-detail-social facebook"
          >
            <img
              src={facebook}
              alt="I am Studio - facebook"
              style={{ height: '25px', width: '25px' }}
            />
          </a>
          <a
            href={data.contact.instagram}
            target="_blank"
            rel="noopener noreferrer"
            className="contact-detail-social instagram"
          >
            <img
              src={instagram}
              alt="I am Studio - instagram"
              style={{ height: '25px', width: '25px' }}
            />
          </a>
          <a
            href={data.contact.twitter}
            target="_blank"
            rel="noopener noreferrer"
            className="contact-detail-social twitter"
          >
            <img
              src={be}
              alt="I am Studio - twitter"
              style={{ height: '25px', width: '25px' }}
            />
          </a>
        </div>
      </section>
    </main>
  );
};

const contactTitle = "CONTACTS";
const contactDescription = "I am Studio is based in I am Space. We are situated in the heart of Berlin but we are a dynamic team with many of us working from different countries and we are always looking to hire new talents.";

const ContactPage = ({ data }) => {
  const { markdownRemark: post } = data;
  return (
    <Layout>
      <Helmet titleTemplate={post.frontmatter.meta?.title || contactTitle}>
        <title>{post.frontmatter.meta?.title || contactTitle}</title>
        <meta name="info" content={post.frontmatter.meta?.description || contactDescription} />
        <meta name="description" content={post.frontmatter.meta?.description || contactDescription} />
        <meta property="og:title" content={post.frontmatter.meta?.title || contactTitle} />
        <meta property="og:description" content={post.frontmatter.meta?.description || contactDescription} />
      </Helmet>
      <ContactPageTemplate data={post.frontmatter} />
    </Layout>
  );
};

ContactPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ContactPage;

export const contactPageQuery = graphql`
  query ContactPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        meta {
          title
          description
        }
        header {
          heading
          description
          gallery {
            alt
            image
          }
        }
        contact {
          heading
          description
          email
          address
          phone
          facebook
          instagram
          twitter
        }
      }
    }
  }
`;
